import React, { useEffect, useRef, useState } from 'react';
import { COUNTRIES } from '../../../resources/countries';
import { TIME_ZONES } from '../../../resources/timezones';
import { Step2Type, useRegistrationContext } from '../../../state/registration/provider';
import * as Yup from 'yup';
import {
  ROLE_MAT_COORDINATOR,
  ROLE_SCHOOL_COORDINATOR,
  ROLE_TEACHER,
  ROLE_TEACHER_FREE,
} from '../../../resources/roles';
import { useQuery } from 'react-query';
import { postEmailCheck, postValidateToken, validateUpgradeAccount } from '../../../service/api';
import { SelectOptionType } from '../../../service/types';
import Input from '../../../form/input/input';
import Button from '../../../global/button/button';
import Selector from '../../../form/select/selector';
import Choice from '../../../form/choice/choice';
import { LinkStyled } from '../../../global/link/link.styled';
import { debounce } from 'lodash';
import EulaPdf from '../../../resources/pdfs/2022-06_End_User_Licence_Agreement_-_GTT.pdf';
import Tcpdf from '../../../resources/pdfs/2024-06_GTT_Ts_and_Cs.pdf';
import { VALIDATE_TOKEN } from '../../../service/queryKeys';
import useModal from '../../../hooks/useModal';
import UpgradeModal from './modals/UpgradeModal';
import FormObserver from './FormObserver';
import MessageAtMenuList from '../../../form/select/components/messageAtMenuList';
import Label from '../../../form/common/label';
import RadioGroupOnBlock from '../../../form/choice/radioGroupOnBlock';
import Alert from '../../../global/alert/alert';
import { ROLES_WITHOUT_ADDITIONAL_INFO } from './RegistrationPage';
import ReCAPTCHA from 'react-google-recaptcha';
import useOnScreen from '../../../hooks/useOnScreen';
import { getSchoolVerifySchools } from '../../../service/api/schoolVerifyApi';
import Icon from '../../../global/icon/icon';
import { GLOBAL_ICONS } from '../../../resources/vars';
import { School } from '../../../@types/Entity/School';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { hubspotRegistration } from '../../../service/api/hubspotApi';
import { checkSchoolUrn } from "../../../service/api/schoolApi";

export const GTT_SOURCE_OPTIONS_OTHER = 'Other';
export const GTT_SOURCE_OPTIONS = [
  { label: 'Worked with EBE before', value: 'Worked with EBE before' },
  { label: 'Recommended by a colleague', value: 'Recommended by a colleague' },
  { label: 'Recommended by another organisation', value: 'Recommended by another organisation' },
  { label: 'Marketing email', value: 'Marketing email' },
  { label: 'Social media', value: 'Social media' },
  { label: 'Google/internet search', value: 'Google/internet search' },
  { label: 'At a conference/event', value: 'At a conference/event' },
  { label: 'David Ross Education Trust', value: 'David Ross Education Trust' },
  { label: 'Other (please specify)', value: GTT_SOURCE_OPTIONS_OTHER },
];

export const CATEGORY_1_OPTIONS = [
  { value: 'Government funded', label: 'Government funded' },
  { value: 'Independent/fee-paying', label: 'Independent/fee-paying' },
];
export const CATEGORY_2_OPTIONS = [
  { value: 'International', label: 'International' },
  { value: 'Primarily local students', label: 'Primarily local students' },
];
export const CATEGORY_3_OPTIONS = [
  { value: 'Academically selective', label: 'Academically selective' },
  { value: 'Open to all', label: 'Open to all' },
];

const initialCoordinatorValues = [
  ['first_name', '', 'First Name'],
  ['last_name', '', 'Last Name'],
  ['role_description', '', 'Job Title'],
  ['email', '', 'Coordinator Email'],
  ['confirm_email', '', 'Confirm Coordinator Email'],
  ['password', '', 'Password'],
  ['confirm_password', '', 'Confirm Password'],
  ['gtt_source', '', 'How did you hear about the GTT?'],
  ['gtt_source_other', '', 'Please specify'],
  ['school', null, 'School/college'],
  ['age_range', [], 'School/college age range'],
  ['category_1', '', 'School funding'],
  ['category_2', '', 'Student population'],
  ['category_3', '', 'Academy selection'],
  ['socioeconomic_composition', '', 'Please describe the socio-economic composition of your school'],
  ['country', '', 'Country'],
  ['timezone', '', 'Time Zone'],
  ['terms_and_conditions', false, 'Terms and conditions and EULA'],
];

const initialStarterValues = [
  ['first_name', '', 'First Name'],
  ['last_name', '', 'Last Name'],
  ['email', '', 'Email'],
  ['confirm_email', '', 'Confirm Email'],
  ['password', '', 'Password'],
  ['confirm_password', '', 'Confirm Password'],
  ['gtt_source', '', 'How did you hear about the GTT?'],
  ['gtt_source_other', '', 'Please specify'],
  ['school', null, 'Organisation'],
  ['country', '', 'Country'],
  ['timezone', '', 'Time Zone'],
  ['terms_and_conditions', false, 'Terms and conditions and EULA'],
];

const Step2 = () => {
  const { setState, setStep, step1, step2, step4, initialPage, previousPage, submitForm } = useRegistrationContext();
  const [step2Values] = useState<Step2Type>({ ...(step2 as Step2Type) });

  const [showErrorMessage, setShowErrorMessage] = useState(false);

  setTimeout(() => setShowErrorMessage(true), 12000);

  useEffect(() => {
    document.getElementById('root')!.scrollTo(0, 0);
  }, []);

  const validateTokenQuery = useQuery(
    [VALIDATE_TOKEN, step1?.token],
    () => postValidateToken(step1?.token, step1?.role),
    { enabled: step1?.token != null }
  );

  const emailValidationDebounced = debounce(async (value, resolve) => {
    try {
      const response = await postEmailCheck(value as string);
      resolve(response.data.data.valid);
    } catch (error) {
      resolve(false);
    }
  }, 500);

  const [upgradeModal, toggleUpgradeModal, setUpgradeProps] = useModal((props: any) => <UpgradeModal {...props} />);

  const SCHEMA = Yup.object({
    first_name: Yup.string().label('First name').trim().max(32).required(),
    last_name: Yup.string().label('Last name').trim().max(32).required(),
    organisation: Yup.string()
      .label('Organisation')
      .trim()
      .max(150)
      .test('is-organisation-required', 'Organisation is a required field', (value) => {
        if (step1?.role !== ROLE_MAT_COORDINATOR) {
          return true;
        }

        return value !== '' && value != null;
      }),
    school: Yup.object({
      id: Yup.string().nullable(),
      name: Yup.string().label('Name').trim().max(150),
      urn: Yup.string().label('Urn').nullable().trim().max(8),
      schoolUrnExisted: Yup.boolean().required().oneOf([false], 'A school with this URN already exists!'),
    })
      .nullable()
      .test('is-school-required', 'School is a required field', (value) => {
        if ((step1?.role === ROLE_TEACHER && step1.token) || step1?.role === ROLE_MAT_COORDINATOR) {
          return true;
        }

        return value !== null;
      }),
    country: Yup.string().test('is-country-required', 'Country is a required field', (value, context) => {
      const values = context.parent;
      if (
        step1?.role === ROLE_TEACHER ||
        (step1?.role === ROLE_SCHOOL_COORDINATOR && !(values.school && !values.school.id && !values.school.urn))
      ) {
        return true;
      }

      return value !== '' && value != null;
    }),
    timezone: Yup.string().test('is-timezone-required', 'Timezone is a required field', (value, context) => {
      const values = context.parent;
      if (
        step1?.role === ROLE_TEACHER ||
        (step1?.role === ROLE_SCHOOL_COORDINATOR && !(values.school && !values.school.id && !values.school.urn))
      ) {
        return true;
      }

      return value !== '' && value != null;
    }),
    email: Yup.string()
      .label('Email')
      .trim()
      .email()
      .max(180)
      .required()
      .test('is-email-taken', 'Email already taken', (value) => {
        // When role is school, the email validation is handled though a modal
        // The same rule will now be implemented for teacher
        if (
          step1?.role === ROLE_SCHOOL_COORDINATOR ||
          step1?.role === ROLE_MAT_COORDINATOR ||
          step1?.role === ROLE_TEACHER
        ) {
          return true;
        }

        return new Promise((resolve) => emailValidationDebounced(value, resolve));
      }),
    confirm_email: Yup.string().test('is-email-confirmed', 'Emails must match', (value, testContext) => {
      // If the emails is blocked it was already "validated"
      if (step2Values?.blocked?.email) {
        return true;
      }

      return (value ?? '').trim().toLowerCase() === (testContext.parent.email ?? '').trim().toLowerCase();
    }),
    password: Yup.string()
      .label('Password')
      .matches(
        /^(?=.*[a-zA-Z])(?=.*[0-9]).{8,255}$/,
        'Passwords must be a minimum of 8 characters in length and contain at least one letter and one number'
      )
      .required(),
    confirm_password: Yup.string().oneOf([Yup.ref('password')], 'Passwords must match'),
    gtt_source: Yup.string().test('is-gtt-source-required', 'This field is required', (value) => {
      if (
        !(
          step1?.role === ROLE_SCHOOL_COORDINATOR ||
          (step1?.role === ROLE_TEACHER && !step1?.token) ||
          step1?.role === ROLE_TEACHER_FREE
        )
      ) {
        return true;
      }

      return value !== '' && value != null;
    }),
    gtt_source_other: Yup.string().when('gtt_source', {
      is: GTT_SOURCE_OPTIONS_OTHER,
      then: Yup.string().required('This is a required field'),
    }),
    terms_and_conditions: Yup.boolean().required().oneOf([true], 'The terms and conditions must be accepted.'),

    // SchoolForm properties moved from step4
    role_description: Yup.string().test('is-role_description-required', 'This field is required', (value) => {
      if (step1?.role !== ROLE_SCHOOL_COORDINATOR) {
        return true;
      }
      return (value ?? '').trim() !== '';
    }),
    age_range: Yup.array().test('is-age-rage-required', 'This field is required', (value, context) => {
      const values = context.parent;
      if (step1?.role !== ROLE_SCHOOL_COORDINATOR || !(values.school && !values.school.id && !values.school.urn)) {
        return true;
      }

      return (value ?? []).length > 0;
    }),
    category_1: Yup.string().test('is-category-1-required', 'This field is required', (value, context) => {
      const values = context.parent;
      if (step1?.role !== ROLE_SCHOOL_COORDINATOR || !(values.school && !values.school.id && !values.school.urn)) {
        return true;
      }

      return value !== '' && value != null;
    }),
    category_2: Yup.string().test('is-category-2-required', 'This field is required', (value, context) => {
      const values = context.parent;
      if (step1?.role !== ROLE_SCHOOL_COORDINATOR || !(values.school && !values.school.id && !values.school.urn)) {
        return true;
      }

      return value !== '' && value != null;
    }),
    category_3: Yup.string().test('is-category-3-required', 'This field is required', (value, context) => {
      const values = context.parent;
      if (step1?.role !== ROLE_SCHOOL_COORDINATOR || !(values.school && !values.school.id && !values.school.urn)) {
        return true;
      }

      return value !== '' && value != null;
    }),
    socioeconomic_composition: Yup.string().test(
      'is-socioeconomic_composition-required',
      'This field is required',
      (value, context) => {
        const values = context.parent;
        if (
          step1?.role !== ROLE_SCHOOL_COORDINATOR ||
          !(values.school && !values.school.id && !values.school.urn) ||
          context.parent.category_2 !== 'International'
        ) {
          return true;
        }
        return value !== '' && value != null;
      }
    ),
  });

  const completeStep = async (callback?: () => void) => {
    if (step1?.token != null) {
      try {
        await postValidateToken(step1?.token, step1?.role);
        if (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role)) {
          await submitForm(callback);
        } else {
          setState({
            skipStep3: true,
            currentPage: 4,
          });
        }
      } catch (e) {
        setState({
          step1: {
            ...step1,
            token: null,
          },
          currentPage: 3,
        });
      }

      return;
    }

    if (step1?.role === ROLE_TEACHER_FREE) {
      setState({
        step3: true,
        currentPage: 4,
        skipStep3: true,
      });
      return;
    }

    setState({
      step1: {
        ...step1,
        token: null,
      },
      currentPage: 3,
    });
  };

  const formValidation = (values: any, role: string, showExtraFields?: any) => {
    const errorFields: Array<string> = [];

    if (role === ROLE_SCHOOL_COORDINATOR) {
      const entries: any = Object.entries(values).filter((value: any) => value[0] != 'organisation');

      initialCoordinatorValues.map((value: any, index: number) => {
        if (
          !['gtt_source_other', 'age_range', 'socioeconomic_composition'].includes(value[0]) &&
          value[1] === entries[index][1]
        )
          if (
            ['category_1', 'category_2', 'category_3', 'country', 'timezone'].includes(entries[index][0]) &&
            !showExtraFields
          ) {
            /*Do nothing*/
          } else errorFields.push(value[2]);
        if (value[0] === 'gtt_source_other' && values.gtt_source === 'Other' && value[1] === entries[index][1])
          errorFields.push(value[2]);

        if (value[0] === 'age_range' && entries[index][1]?.length === 0) {
          if (step1?.role === ROLE_SCHOOL_COORDINATOR && values.school && !values.school.id && !values.school.urn)
            errorFields.push(value[2]);
        }

        if (
          value[0] === 'socioeconomic_composition' &&
          values.category_2 === 'International' &&
          value[1] === entries[index][1]
        )
          if (step1?.role === ROLE_SCHOOL_COORDINATOR && values.school && !values.school.id && !values.school.urn)
            errorFields.push(value[2]);
      });
      if (values.email !== values.confirm_email) errorFields.push("Email and Confirm Email fields don't match");
      if (values.password !== values.confirm_password)
        errorFields.push("Password and Confirm Password fields don't match");

      if (
        step1?.role === ROLE_SCHOOL_COORDINATOR &&
        values.school &&
        values.school.schoolUrnExisted
      ) {
        errorFields.push(
          <>
            A school with this URN already exists. Please{' '}
            <LinkStyled href="mailto:support@evidencebased.education?subject=Subject" target="_blank">
              contact us here
            </LinkStyled>{' '}
            to help
          </>
        );
      }

      return errorFields;
    } else if (role === ROLE_TEACHER_FREE) {
      const starterEntries = Object.entries(values).filter((entry: any) =>
        initialStarterValues.map((value: any) => value[0]).includes(entry[0])
      );

      initialStarterValues.map((value: any, index: number) => {
        if (value[0] != 'gtt_source_other' && value[1] === starterEntries[index][1]) errorFields.push(value[2]);
        if (value[0] === 'gtt_source_other' && values.gtt_source === 'Other' && value[1] === starterEntries[index][1])
          errorFields.push(value[2]);
      });
      if (values.email !== values.confirm_email) errorFields.push("Email and Confirm Email fields don't match");
      if (values.password !== values.confirm_password)
        errorFields.push("Password and Confirm Password fields don't match");

      return errorFields;
    } else return;
  };

  const ref = useRef<HTMLDivElement>(null);
  const isVisible = useOnScreen(ref);
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors, isSubmitting, isValid },
    setValue,
    watch,
    getValues,
  } = useForm({
    resolver: yupResolver(SCHEMA),
    defaultValues: {
      first_name: step2Values?.first_name ?? '',
      last_name: step2Values?.last_name ?? '',
      role_description: step2Values?.role_description ?? '',
      email: step2Values?.email ?? '',
      confirm_email: step2Values?.email ?? '',
      password: step2Values?.password ?? '',
      confirm_password: step2Values?.password ?? '',
      gtt_source: step2Values?.gtt_source ?? '',
      gtt_source_other: step2Values?.gtt_source_other ?? '',
      organisation: step2Values?.organisation ?? '',
      school: step2Values?.school ?? null,
      age_range: step2Values?.age_range ?? [],
      category_1: step2Values?.category_1 ?? '',
      category_2: step2Values?.category_2 ?? '',
      category_3: step2Values?.category_3 ?? '',
      socioeconomic_composition: step2Values?.socioeconomic_composition ?? '',
      country: step2Values?.country ?? '',
      timezone: step2Values?.timezone ?? '',
      terms_and_conditions: step2Values?.terms_and_conditions ?? false,
    },
  });

  return (
    <>
      {upgradeModal}
      {step1?.role === ROLE_TEACHER && !step1?.token && (
        <Alert type={'info'} className={'field-mb'}>
          <p>
            This registration form is for an individual Great Teaching Toolkit subscription, which costs £299 (ex VAT).
            If you are wanting to enrol multiple staff from your school/college, please click{' '}
            <LinkStyled href={'/register?r=school'}>here</LinkStyled>.
          </p>
        </Alert>
      )}
      {step1?.role === ROLE_SCHOOL_COORDINATOR && (
        <Alert type={'info'} className={'field-mb'}>
          <p>
            The details entered below should be those of your nominated Great Teaching Coordinator (that is the person
            leading the training at your school/college). Separate billing information can be entered on the following
            page.
          </p>
        </Alert>
      )}
      {step1?.role === ROLE_TEACHER_FREE && (
        <Alert type={'info'} className={'field-mb'}>
          <p>
            Please complete the following form to create your free Great Teaching Toolkit Starter Account. If your
            school or college already has a subscription, please contact your Coordinator for your specific enrolment
            link.
          </p>
        </Alert>
      )}
      <form
        className={'py-5'}
        onSubmit={handleSubmit(async (values) => {
          setIsLoading(true);

          if (step1?.role === ROLE_TEACHER_FREE || step1?.role === ROLE_SCHOOL_COORDINATOR) {
            await hubspotRegistration({
              first_name: values.first_name,
              last_name: values.last_name,
              email: values.email,
              role_description: values.role_description,
              school: values.school!.name,
              role: step1.role,
            });
          }

          if (
            (step1?.role === ROLE_SCHOOL_COORDINATOR && !step1?.token) ||
            step1?.role === ROLE_MAT_COORDINATOR ||
            step1?.role === ROLE_TEACHER
          ) {
            validateUpgradeAccount({ email: values.email, role: step1.role }).then((response) => {
              const data = response.data.data;
              if (data.exists) {
                if (data.valid) {
                  setUpgradeProps({
                    text: `Your existing GTT Starter account will now be upgraded to ${
                      step1?.role === ROLE_SCHOOL_COORDINATOR
                        ? 'a GT Coordinator'
                        : step1?.role === ROLE_MAT_COORDINATOR
                          ? 'a Group Coordinator'
                          : 'an Individual Teacher'
                    } account - any active surveys (and associated responses) will be marked as complete.`,
                    handleSubmit: () => completeStep(() => setIsLoading(false)),
                    valid: true,
                  });
                  toggleUpgradeModal(true);
                  setIsLoading(false);
                  return;
                }

                setUpgradeProps({
                  text: `This email address is already being used. Please specify a different email address`,
                });
                toggleUpgradeModal(true);
                setIsLoading(false);
                return;
              }

              completeStep(() => setIsLoading(false));
            });
            return;
          }

          completeStep(() => setIsLoading(false));
        })}
      >
        <FormObserver step={'step2'} values={getValues() as Step2Type} />
        <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
          <div className={'field-mb'}>
            <Input
              {...register('first_name')}
              id={'first_name'}
              label="First Name"
              required
              error={errors.first_name?.message}
              placeholder={`Please enter ${
                step1?.role === ROLE_SCHOOL_COORDINATOR ? 'the Coordinator’s' : 'your'
              } first name`}
            />
          </div>
          <div className={'field-mb'}>
            <Input
              {...register('last_name')}
              id={'last_name'}
              required
              error={errors.last_name?.message}
              label="Last Name"
              placeholder={`Please enter ${
                step1?.role === ROLE_SCHOOL_COORDINATOR ? 'the Coordinator’s' : 'your'
              } last name`}
            />
          </div>
        </div>
        {step1?.role === ROLE_SCHOOL_COORDINATOR && (
          <div className={'field-mb'}>
            <Input
              {...register('role_description')}
              id={'role_description'}
              required
              error={errors.role_description?.message}
              label="Job Title"
              placeholder={`Please describe ${
                step1?.role === ROLE_SCHOOL_COORDINATOR ? 'the Coordinator’s' : 'your'
              } job role`}
            />
          </div>
        )}
        <div className={'field-mb'}>
          <Input
            {...register('email')}
            id={'email'}
            required
            error={errors.email?.message}
            type={'email'}
            label={step1?.role === ROLE_SCHOOL_COORDINATOR ? 'Coordinator Email' : 'Email'}
            placeholder={`Please enter ${step1?.role === ROLE_SCHOOL_COORDINATOR ? 'the Coordinator’s' : 'your'} email`}
            disabled={step2Values?.blocked?.email}
          />
        </div>
        {!step2Values?.blocked?.email && (
          <div className={'field-mb'}>
            <Input
              {...register('confirm_email')}
              id={'confirm_email'}
              required
              error={errors.confirm_email?.message}
              type={'email'}
              label={`Confirm ${step1?.role === ROLE_SCHOOL_COORDINATOR ? 'Coordinator' : ''} Email`}
              placeholder={`Please repeat ${
                step1?.role === ROLE_SCHOOL_COORDINATOR ? 'the Coordinator’s' : 'your'
              } email`}
            />
          </div>
        )}
        <div className={'field-mb'}>
          <Input
            {...register('password')}
            id={'password'}
            required
            error={errors.password?.message}
            type={'password'}
            label="Password"
            placeholder={'Please enter your password'}
          />
        </div>
        <div className={'field-mb'}>
          <Input
            {...register('confirm_password')}
            id={'confirm_password'}
            required
            error={errors.confirm_password?.message}
            type={'password'}
            label="Confirm Password"
            placeholder={'Please repeat your password'}
          />
        </div>
        {(step1?.role === ROLE_SCHOOL_COORDINATOR ||
          (step1?.role === ROLE_TEACHER && !step1?.token) ||
          step1?.role === ROLE_TEACHER_FREE) && (
          <>
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'gtt_source'}
                    label={'How did you hear about the GTT?'}
                    placeholder={'Please enter how did you hear about the GTT'}
                    required
                    value={GTT_SOURCE_OPTIONS.find((option) => option.value === field.value)}
                    options={GTT_SOURCE_OPTIONS}
                    onChange={(option: any) => {
                      if (option.value !== GTT_SOURCE_OPTIONS_OTHER) {
                        setValue('gtt_source_other', '');
                      }

                      field.onChange(option.value);
                    }}
                    isClearable={false}
                    error={errors.gtt_source?.message}
                  />
                )}
                name={'gtt_source'}
              />
            </div>
            {watch('gtt_source') === GTT_SOURCE_OPTIONS_OTHER && (
              <div className={'field-mb'}>
                <Input
                  {...register('gtt_source_other')}
                  id={'gtt_source_other'}
                  required
                  error={errors.gtt_source_other?.message}
                  label="Please specify"
                  placeholder={'Please enter how did you hear about the GTT'}
                />
              </div>
            )}
            <div className={'text-muted mb-2'}>
              <p>The details below are about your school or college.</p>
            </div>
          </>
        )}
        {!(step1?.role === ROLE_TEACHER && step1.token) && (
          <div className={'field-mb'}>
            {step1?.role !== ROLE_MAT_COORDINATOR ? (
              <Controller
                control={control}
                render={({ field }) => (
                  <>
                    <Selector
                      {...field}
                      isAsync={step1?.token == null}
                      isCreatableSelect
                      id={'school'}
                      label={'School/college'}
                      placeholder={'Start typing to select your school/college'}
                      required
                      value={
                        field.value != null
                          ? step1?.token != null
                            ? (function () {
                              const school = validateTokenQuery.data?.data.data.find(
                                (school) => school.id === field.value?.id
                              );
                              return school ? { label: school.name, value: school.id } : null;
                            })()
                            : { label: field.value.name, value: field.value.name }
                          : null
                      }
                      options={
                        step1?.token != null
                          ? validateTokenQuery.data?.data.data?.map((school) => ({
                            label: school.name,
                            value: school.id,
                            ...school,
                          }))
                          : []
                      }
                      isLoading={validateTokenQuery.isFetching}
                      onChange={async (option: SelectOptionType & School) => {
                        let response = { data: { data: { id: null } } };
                        if (null === option) {
                          field.onChange(null);
                          setValue('age_range', []);
                          setValue('category_1', '');
                          setValue('category_2', '');
                          setValue('category_3', '');
                          setValue('socioeconomic_composition', '');
                          setValue('country', '');
                          return;
                        }

                        if(option.urn !== null && typeof option.urn !== 'undefined'){
                          response = await checkSchoolUrn(option.urn);
                        }

                        field.onChange({
                          id: option?.id,
                          name: option?.name ?? option.value,
                          urn: option?.urn,
                          schoolUrnExisted: response.data.data.id !== null
                        });
                      }}
                      loadOptions={(input: string) =>
                        getSchoolVerifySchools(input).then((response) => {
                          return response.data
                            .map((school) => ({
                              ...school,
                              label: `${school.name}, ${
                                ['', 'Does not apply'].includes(school.la) ? '' : `${school.la},`
                              } ${school.postcode === '' ? '' : `${school.postcode},`} ${school.urn}`,
                              value: school.name,
                            }))
                            .sort((a, b) => a.name.localeCompare(b.name));
                        })
                      }
                      cacheOptions
                      noOptionsMessage={({ inputValue }: { inputValue: string }) => {
                        if (!inputValue || step1?.token != null) {
                          return <div>No options</div>;
                        }

                        return (
                          <div>
                            <div className={'text-info flex items-center justify-center gap-5'}>
                              <Icon icon={GLOBAL_ICONS['info']} container={false} elementSize={20} />
                              <i>No school exists with those details.</i>
                            </div>
                            <LinkStyled
                              href={'#'}
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            >
                              Click here to add your school
                            </LinkStyled>
                          </div>
                        );
                      }}
                      error={errors.school?.message}
                    />
                  </>
                )}
                name={'school'}
              />
            ) : (
              <Input
                {...register('organisation')}
                id={'organisation'}
                required
                error={errors.organisation?.message}
                label={'Organisation'}
                placeholder={`Please enter your organisation name`}
              />
            )}
          </div>
        )}
        {step1?.role === ROLE_SCHOOL_COORDINATOR && watch('school') && !watch('school')?.id && !watch('school')?.urn && (
          <>
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    isMulti
                    isCreatableSelect
                    id={'age_range'}
                    label={'School/college age range'}
                    placeholder={'Please select one or more age ranges'}
                    options={[
                      { value: '4-11', label: '4-11' },
                      { value: '11-16', label: '11-16' },
                      { value: '11-18', label: '11-18' },
                    ]}
                    value={field.value.map((value) => {
                      {
                        return {
                          label: value,
                          value,
                        };
                      }
                    })}
                    onChange={(options: any) =>
                      field.onChange(options == null ? [] : options.map((option: SelectOptionType) => option.value))
                    }
                    required
                    error={errors.age_range?.message}
                    isClearable={false}
                    customComponents={{
                      MenuList: function MenuList(menuListProps) {
                        return (
                          <MessageAtMenuList
                            message={"Don't see your age range? Add it by populating the field above."}
                            {...menuListProps}
                          />
                        );
                      },
                    }}
                  />
                )}
                name={'age_range'}
              />
            </div>
            <div className={'field-mb'}>
              <Label
                label={'School funding'}
                id={'school_funding'}
                description={'Please select the box which most closely describes your school '}
                required
              />
              <div className={'flex-grow'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <RadioGroupOnBlock
                      id={'category_1'}
                      label={'Founding'}
                      hideLabel
                      options={CATEGORY_1_OPTIONS}
                      optionChecked={field.value}
                      handleChange={(value) => field.onChange(value)}
                      error={errors.category_1?.message}
                    />
                  )}
                  name={'category_1'}
                />
              </div>
            </div>
            <div className={'field-mb'}>
              <Label
                label={'Student population'}
                id={'student_population'}
                required
                description={'Please select the box which most closely describes your school'}
              />
              <div className={'flex-grow'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <RadioGroupOnBlock
                      id={'category_2'}
                      label={'Scope'}
                      hideLabel
                      options={CATEGORY_2_OPTIONS}
                      optionChecked={field.value}
                      handleChange={(value) => {
                        field.onChange(value);
                      }}
                      error={errors.category_2?.message}
                    />
                  )}
                  name={'category_2'}
                />
              </div>
            </div>
            <div className={'field-mb'}>
              <Label
                label={'Academy selection'}
                id={'academy_selection'}
                required
                description={'Please select the box which most closely describes your school'}
              />
              <div className={'flex-grow'}>
                <Controller
                  control={control}
                  render={({ field }) => (
                    <RadioGroupOnBlock
                      id={'category_3'}
                      label={'Selection'}
                      hideLabel
                      options={CATEGORY_3_OPTIONS}
                      optionChecked={field.value}
                      handleChange={(value) => field.onChange(value)}
                      error={errors.category_3?.message}
                    />
                  )}
                  name={'category_3'}
                />
              </div>
            </div>
            {watch('category_2') === 'International' && (
              <div className={'field-mb'}>
                <Input
                  {...register('socioeconomic_composition')}
                  id={'socioeconomic_composition'}
                  required
                  error={errors.socioeconomic_composition?.message}
                  label="Please describe the socio-economic composition of your school:"
                  type={'textarea'}
                />
              </div>
            )}
          </>
        )}
        {([ROLE_MAT_COORDINATOR, ROLE_TEACHER_FREE].includes(step1!.role!) ||
          (step1?.role === ROLE_SCHOOL_COORDINATOR &&
            watch('school') &&
            !watch('school')?.id &&
            !watch('school')?.urn)) && (
          <div className={'md:grid grid-flow-col grid-cols-2 grid-rows-1 gap-2'}>
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'country'}
                    label={'Country'}
                    placeholder={'Please select a country'}
                    required
                    value={field.value ? COUNTRIES.find((country) => country.value === field.value) : null}
                    options={COUNTRIES}
                    onChange={(option: any) => field.onChange(option.value)}
                    isClearable={false}
                    error={errors.country?.message}
                  />
                )}
                name={'country'}
              />
            </div>
            <div className={'field-mb'}>
              <Controller
                control={control}
                render={({ field }) => (
                  <Selector
                    {...field}
                    id={'timezone'}
                    label={'Time Zone'}
                    placeholder={'Please select a time zone'}
                    required
                    value={TIME_ZONES.find((timezone) => timezone.value === field.value)}
                    options={TIME_ZONES}
                    onChange={(option: any) => field.onChange(option.value)}
                    isClearable={false}
                    error={errors.timezone?.message}
                    autoFocus={true}
                  />
                )}
                name={'timezone'}
              />
            </div>
          </div>
        )}
        <div className={'bg-gray-100 p-5 field-mb'}>
          <Controller
            control={control}
            render={({ field }) => (
              <Choice
                id={'terms'}
                label={
                  <>
                    I agree to the{' '}
                    <LinkStyled href={Tcpdf} target={'_blank'}>
                      terms and conditions
                    </LinkStyled>{' '}
                    and{' '}
                    <LinkStyled href={EulaPdf} target={'_blank'}>
                      End User Licence Agreement (EULA)
                    </LinkStyled>
                  </>
                }
                checked={field.value}
                onChange={() => field.onChange(!field.value)}
              />
            )}
            name={'terms_and_conditions'}
          />
        </div>
        {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && step1?.token && (
          <ReCAPTCHA
            sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY as string}
            onChange={(token) => setStep('step4', { captcha: token })}
            onErrored={() => setStep('step4', { captcha: null })}
            onExpired={() => setStep('step4', { captcha: null })}
          />
        )}
        <div className={`md:grid grid-flow-col ${initialPage === 1 ? 'grid-cols-2' : 'grid-cols-1'} grid-rows-1 gap-5`}>
          {initialPage === 1 && (
            <Button className={'mt-5'} type="button" onClick={() => previousPage()}>
              Back
            </Button>
          )}
          <Button
            id={`${step1?.role && step1?.role + '_'}registrationForm_page2_submit`}
            className={'mt-5'}
            type="submit"
            disabled={
              isSubmitting ||
              isLoading ||
              (ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) &&
                step1?.token != null &&
                (step4?.captcha ?? '').trim() === '')
            }
          >
            {!isSubmitting && !isLoading
              ? ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && step1?.token
                ? 'Submit'
                : 'Continue'
              : 'Loading...'}
          </Button>
        </div>
        <div ref={ref}>
          {showErrorMessage &&
            (step1?.role === ROLE_SCHOOL_COORDINATOR || step1?.role === ROLE_TEACHER_FREE) &&
            !isValid &&
            isVisible && (
              <div className={'mt-8 text-red-500'}>
                There is an error or missing information in the following fields:
                <ul className={'list-disc'}>
                  {formValidation(
                    watch(),
                    step1.role,
                    step1?.role === ROLE_SCHOOL_COORDINATOR &&
                    watch('school') &&
                    !watch('school')?.id &&
                    !watch('school')?.urn
                  )?.map((value: any) => (
                    <li key={Math.random()}>
                      <span className={'font-bold italic'}>{value}</span>;
                    </li>
                  ))}
                </ul>
              </div>
            )}
        </div>
        {ROLES_WITHOUT_ADDITIONAL_INFO.includes(step1?.role) && step1?.token && (
          <div className={'pt-10'}>
            <Alert type={'info'}>
              Having trouble completing this form? Please{' '}
              <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
                contact us here
              </LinkStyled>{' '}
              to help!
            </Alert>
          </div>
        )}
      </form>
    </>
  );
};
export default Step2;
