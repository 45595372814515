import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { postLogin, postSendResetPasswordMail } from '../../service/api';
import { login } from '../../service/auth';
import { LOGIN_ERROR } from '../../service/errors';
import AuthLayout from '../../layout/auth';
import Button from '../../global/button/button';
import Input from '../../form/input/input';
import { LinkStyled } from '../../global/link/link.styled';
import useModal from '../../hooks/useModal';
import ResetPasswordModal from './ResetPassword/resetPasswordModal';
import { toast } from 'react-toastify';
import MaintenanceModal from './modals/maintenanceModal';
import VideoAlert from '../../guides/components/videoAlert';

interface LoginPageInterface {
  maintenance?: boolean;
}

const LoginPage: React.FC<LoginPageInterface> = ({ maintenance = false }) => {
  const [error, setError] = useState('');
  const [errorEmail, setErrorEmail] = useState('');
  const [loading, setLoading] = useState(false);

  const [niotMaintenance, setNiotMaintenance] = useState<boolean>(false);

  const SCHEMA = Yup.object().shape({
    email: Yup.string().required().email(),
    password: Yup.string().required(),
  });

  const [modal, toggleModal, setModalProps] = useModal((props: any) => <ResetPasswordModal {...props} />, {
    title: 'Reset password',
    size: 'sm',
  });

  const customErrorMessage: JSX.Element = (
    <div>
      <p>There has been an error with logging you in.</p>
      {/*<p>
        If you are trying to access your NIOT account, please try to log in on <LinkStyled href={"https://niot.greatteaching.com/login"} target={"_blank"}>this page</LinkStyled>{' '}
        instead, using the same credentials.
      </p>
      <p>
        If this is not the case, please{' '}
        <LinkStyled href={'mailto:support@evidencebased.education?subject=Subject'} target={'_blank'}>
          contact us here
        </LinkStyled>
        .
      </p>*/}
    </div>
  );

  const [maintenanceModal, toggleMaintenanceModal, setModalMaintenanceProps] = useModal(
    (props: any) => (
      <MaintenanceModal niotMaintenance={niotMaintenance} newNiotInstance={true} errorEmail={errorEmail} {...props} />
    ),
    {}
  );

  useEffect(() => {
    if (maintenance) {
      setModalMaintenanceProps({ size: 'lg', preventClose: true, preventCloseOnClickOnMask: true });
      toggleMaintenanceModal(true);
    }
  }, []);

  useEffect(() => {
    if (['niotUserLogin', 'niotUserResetPassword'].includes(error)) {
      toggleMaintenanceModal(true);
      setModalMaintenanceProps({ size: 'lg', preventClose: true, preventCloseOnClickOnMask: true });
      setNiotMaintenance(true);
    }
  }, [error]);

  return (
    <AuthLayout
      noBottomPadding
      title={!maintenance ? 'Log into your account' : "Sorry, we're down for maintenance"}
      description={
        !maintenance ? (
          <>
            <p>
              <b>Need an account?</b>{' '}
              <LinkStyled
                href={'https://evidencebased.education/great-teaching-toolkit-leaders/#cost'}
                target={'_blank'}
              >
                Click here to sign up
              </LinkStyled>
            </p>
            <div className="w-full">
              <VideoAlert
                classes={'mt-4'}
                showHideForever={false}
                fullWidth
                showHide={false}
                centerData
                largeDescription
                data={{
                  description: (
                    <p>
                      If you have a National Institute of Teaching account, please use{' '}
                      <LinkStyled href={'https://niot.greatteaching.com/login'}>this new login page</LinkStyled>
                    </p>
                  ),
                }}
                setVideo={undefined}
              />
            </div>
          </>
        ) : (
          <></>
        )
      }
    >
      {maintenanceModal}
      {modal}
      <Formik
        initialValues={{ email: '', password: '' }}
        isInitialValid={false}
        validationSchema={SCHEMA}
        validateOnMount
        onSubmit={(values) => {
          if (maintenance) {
            toast.error("Sorry, we're down for maintenance");
          } else {
            setLoading(true);

            postLogin(values)
              .then((response) => {
                login(response.data.token);
              })
              .catch(({ response }) => {
                setErrorEmail(values.email);
                if (response.status === 401) {
                  setError('Invalid credentials.' === response.data.message ? LOGIN_ERROR : response.data.message);
                  return;
                }
                setError('An error has occurred.');
              })
              .finally(() => {
                setLoading(false);
              });
          }
        }}
      >
        {({ values, handleSubmit, handleChange, isValid, errors }) => (
          <form className={`py-5 ${maintenance ? 'cursor-not-allowed' : ''}`} onSubmit={handleSubmit}>
            <div className={`field-mb ${maintenance ? 'pointer-events-none' : ''}`}>
              <Input
                disabled={maintenance}
                id={'email'}
                type={'email'}
                label={'Email Address'}
                placeholder={'Enter your email address'}
                required
                value={values.email}
                onChange={(e) => {
                  handleChange(e);
                  setError('');
                }}
              />
            </div>
            <div className={`field-mb ${maintenance ? 'pointer-events-none' : ''}`}>
              <Input
                disabled={maintenance}
                id={'password'}
                type={'password'}
                label={'Password'}
                hintText={
                  <Button
                    asLink
                    style={{ padding: 0, background: 'transparent' }}
                    disabled={!!(values.email === '' || errors.email) || maintenance}
                    onClick={(e: any) => {
                      e.preventDefault();
                      if (values.email === '' || errors.email) {
                        return;
                      }
                      postSendResetPasswordMail(values.email).catch((response) => {
                        if (response.response.data.message === 'niotUserResetPassword')
                          setError('niotUserResetPassword');
                        else toast.error('An unexpected error has occurred, please try again');
                      });
                      setModalProps({
                        email: values.email,
                      });
                      toggleModal(true);
                    }}
                  >
                    Reset password
                  </Button>
                }
                placeholder={'Enter your password'}
                required
                value={values.password}
                error={error}
                customError={
                  ['niotUserLogin', 'niotUserResetPassword'].includes(error) ? customErrorMessage : undefined
                }
                onChange={(e) => {
                  handleChange(e);
                  setError('');
                }}
              />
            </div>
            <Button
              className={`${maintenance ? 'cursor-not-allowed' : ''} block w-full mt-5`}
              type={'submit'}
              disabled={!isValid || loading || maintenance}
            >
              {loading ? 'Loading...' : 'Continue'}
            </Button>
          </form>
        )}
      </Formik>
    </AuthLayout>
  );
};

export default LoginPage;
